import React, { useEffect } from 'react';
import './QuotationOptions.css';
import { useState } from 'react';
import PropTypes from 'prop-types';
import PlanContainer from '../PlanContainer/PlanContainer';
import { Button } from '../basic';
import { ArrowLeft, DoubleCheck, Question } from '../../assets/icons';
import { formatNumber } from '../../utils/format';
import { useMyContext } from '../../context/global/Context';
import { scrollToInfo } from '../../utils/functions';
import Modal from '../Modal/Modal';
import ErrorModal from '../ErrorModal/ErrorModal';
import Tooltip from '../Tooltip/Tooltip';
import { isEmpty, validateEmail } from '../../utils/validation';
import { useFetchCoverageLegends } from '../../hooks';

const QuotationOptions = ({ coverages = [], setSelectedCoverage = () => { } }) => {
	const { functions: { handlePersonQuote, handleViewPdf }, currentBranch } = useMyContext();

	const { coverageLegends } = useFetchCoverageLegends(currentBranch, 'step2');

	const [compare, setCompare] = useState(false);
	const [coverageItems, setCoverageItems] = useState([]);
	const [legends, setLegends] = useState([]);

	const [coverageClassContainer, setCoverageClassContainer] = useState('w-full m-auto hidden md:grid grid-cols-1 md:grid-cols-3 my-10 gap-6');
	const [infoClassContainer, setInfoClassContainer] = useState('info_container h-full m-auto grid grid-cols-1 md:grid-cols-3 w-full gap-6');
	const [infoBenefitsClassContainer, setInfoBenefitsClassContainer] = useState('info_container_benefits h-full m-auto hidden md:grid md:grid-cols-1 md:grid-cols-3 w-full gap-6');

	const [showModal, setShowModal] = useState(false);

	const [modalType, setModalType] = useState('plain');
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');
	const [modalShow, setModalShow] = useState(false);

	useEffect(() => {
		if (coverages.length > 0) {
			setCoverageItems(coverages.map((coverage, i) => {
				return { id: coverage.coverageId, title: coverage.coverageTopTitle, secondTitle: coverage.coverageBottomTitle, subtitle: coverage.coverageSubtitle, bonus: coverage.description_bonus, btnText: coverage.btn_text, detail: coverage.coverageDescription, recomended: coverage.recommended, benefits: coverage.benefits.map((benefit, j) => { return { title: benefit.description, hasBenefit: benefit.hasBenefit, detail: benefit.details } }), caract: coverage.otherBenefits, otherBenefits: [], newPrice: formatNumber(coverage.feeValue, 'es-AR', 'currency', 'ARS'), oldPrice: formatNumber(coverage.cashFeeValue, 'es-AR', 'currency', 'ARS') };
			}));
		} else {
			setCoverageItems([]);
		}
	}, [coverages]);

	useEffect(() => {
		setLegends(coverageLegends);
	}, [coverageLegends]);

	useEffect(() => {
		setCoverageClassContainer(`w-full m-auto hidden md:grid grid-cols-1 md:grid-cols-${coverageItems.length} my-10 gap-6`);
		setInfoClassContainer(`info_container h-full m-auto grid grid-cols-1 md:grid-cols-${coverageItems.length} w-full ${coverageItems.length > 2 ? 'gap-6' : 'gap-6 md:gap-44'}`);
		setInfoBenefitsClassContainer(`info_container_benefits h-full m-auto hidden md:grid grid-cols-1 md:grid-cols-${coverageItems.length} w-full ${coverageItems.length > 2 ? 'gap-6' : 'gap-6 md:gap-44'}`);
	}, [coverageItems.length]);

	const handleDownloadQuotation = async (obj) => {
		const resultEmail = validateEmail(obj.EMail);

		if (resultEmail === true) {
			const response = await handlePersonQuote(obj, true);

			if (response.error === false) {
				await handleViewPdf();
			} else {
				setModalTitle('Atención');
				setModalText(response.msg);
				setModalType('error');
				setModalShow(true);
			}

			setShowModal(false);
		} else {
			setModalTitle('Atención');
			setModalText('Debes ingresar un mail válido.');
			setModalType('error');
			setModalShow(true);
		}
	}

	const showTooltip = (reference) => {
		document.getElementById(reference).classList.remove('hidden');
		document.getElementById(reference).classList.add('flex');
	}

	const hideTooltip = (reference) => {
		document.getElementById(reference).classList.remove('flex');
		document.getElementById(reference).classList.add('hidden');
	}

	return (
		<>
			<Modal show={showModal} type={'personal_data'} showFunction={setShowModal} handleClose={() => { setShowModal(false) }} handleConfirm={handleDownloadQuotation} title={'Ingrese sus datos para continuar con la descarga'} />
			<ErrorModal type={modalType} text={modalText} title={modalTitle} visible={modalShow} handleVisible={() => { setModalShow(!modalShow) }} />
			<div className='quotationoptions w-full'>
				<div className='w-full flex flex-row items-center justify-center m-auto px-6 md:px-0'>
					<div className={coverageClassContainer}>
						{
							coverageItems.map((coverage, i) => {
								return (
									<PlanContainer
										id={coverage.id}
										size={'w-full max-w-[420px]'}
										title={coverage.title}
										secondTitle={coverage.secondTitle}
										bonusText={coverage.bonus}
										btnText={coverage.btnText}
										subtitle={coverage.subtitle}
										info={coverage.caract}
										recomended={coverage.recomended}
										newPrice={coverage.newPrice}
										oldPrice={coverage.oldPrice}
										setSelectedCoverage={setSelectedCoverage}
									/>
								);
							})
						}
					</div>
					<div className='w-full m-auto md:hidden grid grid-cols-1 my-10 gap-6'>
						{
							coverageItems.map((coverage, i) => {
								if (coverage.recomended === true) {
									return (
										<PlanContainer
											id={coverage.id}
											size={'w-full max-w-[420px]'}
											title={coverage.title}
											secondTitle={coverage.secondTitle}
											bonusText={coverage.bonus}
											btnText={coverage.btnText}
											subtitle={coverage.subtitle}
											info={coverage.caract}
											recomended={coverage.recomended}
											newPrice={coverage.newPrice}
											oldPrice={coverage.oldPrice}
											setSelectedCoverage={setSelectedCoverage}
										/>
									);
								} else {
									return (
										<></>
									);
								}
							})
						}
						{
							coverageItems.map((coverage, i) => {
								if (coverage.recomended === true) {
									return (
										<></>
									);
								} else {
									return (
										<PlanContainer
											id={coverage.id}
											size={'w-full max-w-[420px]'}
											title={coverage.title}
											secondTitle={coverage.secondTitle}
											bonusText={coverage.bonus}
											btnText={coverage.btnText}
											subtitle={coverage.subtitle}
											info={coverage.caract}
											recomended={coverage.recomended}
											newPrice={coverage.newPrice}
											oldPrice={coverage.oldPrice}
											setSelectedCoverage={setSelectedCoverage}
										/>
									);
								}
							})
						}
					</div>
				</div>
				<div className='flex flex-col gap-4 md:w-[40%] w-[75%] m-auto items-center mb-8 md:mb-12'>
					<Button type={'primary'} size={'full'} className={'rounded-lg m-auto py-2 font-semibold text-base uppercase mt-2 hover:bg-opacity-85'} title='descargar cotización' handleClick={() => { setShowModal(true) }} />
					{!compare &&
						<Button handleClick={() => setCompare(!compare)} className={'border-0 py-2 m-auto justify-center text-center font-semibold text-base uppercase bg-white text-primary flex flex-row items-center hover:bg-opacity-85'} size={'full'} title='Comparar Coberturas' icon={<ArrowLeft size={24} color={'rgb(124 19 56 / var(--tw-bg-opacity))'} opacity={1.0} />} />
					}
				</div>
				{compare ?
					<div className='w-full border-t border-grey mb-8 md:mb-12'>
						<div className={infoClassContainer}>
							{
								coverageItems.map((c, index) => {
									return (
										<div className={`flex flex-row justify-center pt-6 md:pt-10 pb-6 md:pb-0 ${c.recomended ? 'bg-white-pink-lighter border-x-shiny-pink border-x-2' : 'bg-white border-l-white border-l border-r-white border-r'} md:px-4`}>
											<div className={`grid items-start w-full max-w-[322px]`}>
												<div>
													<h3 className='font-open-sans text-[32px] font-semibold text-primary leading-10 tracking-normal text-left'>{c.title}{c.secondTitle !== '' ? <span className='text-[20px]'>&nbsp;{c.secondTitle}</span> : <></>}</h3>
													<p className='pt-2 pb-4 font-open-sans text-[18px] font-semibold leading-6 tracking-wide text-left text-primary'>Este plan es para vos si:</p>
													<p className="font-open-sans text-sm font-normal leading-6 tracking-wide text-left text-grey">{c.detail}</p>
												</div>
												<div className='flex flex-col self-end mt-6 md:mt-8'>
													<div className='flex flex-col items-center justify-center gap-2 w-full'>
														<Button type={'primary'} size={'full'} className={'rounded-lg m-auto py-2 font-semibold text-base uppercase mt-2 bg-shiny-pink hover:bg-opacity-85 hover:border-opacity-85'} title='contratar plan' handleClick={() => { setSelectedCoverage(c.id) }} />
														<div className='text-center text-lg font-semibold text-primary'>{c.newPrice} /mes</div>
													</div>
												</div>
											</div>
										</div>
									)
								})
							}
						</div>
						<div className={infoBenefitsClassContainer}>
							{
								coverageItems.map((c, index) => {
									return (
										<div className={`flex justify-center ${c.recomended ? 'bg-white-pink-lighter border-x-shiny-pink border-x-2' : 'bg-white border-l-white border-l border-r-white border-r'} md:pb-6 md:px-4`}>
											<div className="flex flex-col items-start w-full max-w-[322px]">
												<div className='py-4 font-open-sans text-lg font-semibold text-left text-primary mt-4'>
													Y vas a estar cubierto ante:
												</div>
												<div className='flex flex-col gap-4'>
													{c.benefits.map((i, index) => {
														if (i.hasBenefit === 1)
															return (
																<div className='flex flex-row items-center justify-start gap-2'>
																	<DoubleCheck size={24} opacity={1.0} color={'#9EC54C'} />
																	<div className="font-open-sans text-sm font-normal leading-6 tracking-wide text-left text-black">
																		<div className="flex flex-row gap-2">
																			<span>{i.title}</span>
																			<div className={`flex relative${isEmpty(i.detail) ? ' hidden' : ''}`}>
																				<Tooltip text={i.detail} reference={`tooltip_benefit_${c.id}_${index}`} />
																				<div className='flex cursor-pointer' onMouseEnter={() => { showTooltip(`tooltip_benefit_${c.id}_${index}`) }} onMouseLeave={() => { hideTooltip(`tooltip_benefit_${c.id}_${index}`) }}>
																					<Question size={14} color='#DE6180' />
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															)
														else
															return (
																<div className='flex flex-row items-center justify-start gap-2'>
																	-
																</div>
															)
													})}
												</div>
											</div>
											{
												c.otherBenefits.length > 0 ?
													<div className='flex flex-col'>
														<div className='py-4 font-open-sans text-[18px] font-semibold leading-6 tracking-wide text-left text-primary mt-8'>
															Otros servicios incluidos:
														</div>
														<div className='flex flex-col gap-4'>
															{c.otherBenefits.map(i => {
																return (
																	<div className='flex flex-row items-center justify-start gap-2'>
																		<DoubleCheck size={24} opacity={1.0} color={'#9EC54C'} />
																		<div className="font-open-sans text-base font-normal leading-6 tracking-wide text-left text-black">
																			{i}
																		</div>
																	</div>
																)
															})}
														</div>
													</div>
													:
													<></>
											}
										</div>
									)
								})
							}
						</div>
						<div className='lg:w-3/4 xl:w-10/12 w-11/12 2xl:w-11/12 flex flex-col m-auto'>
							{
								legends.length > 0 ?
									<>
										<div className='py-4 font-open-sans text-[18px] font-semibold leading-6 tracking-wide text-left text-primary mt-8'>
											Informacion importante:
										</div>
										<div className="grid grid-cols-1 gap-y-1">
											{
												legends.map(e => {
													return (
														<div className="font-open-sans text-sm font-semibold leading-6 tracking-wide text-left flex flex-row gap-4 text-black">
															<div className="flex items-start">
																<DoubleCheck size={24} opacity={1.0} color={'#9EC54C'} />
															</div>
															{e.legend}
														</div>
													)
												})
											}
										</div>
									</>
									:
									<></>
							}
							<div className='m-auto md:w-[40%] w-[75%] mt-7'>
								<Button handleClick={() => { setCompare(!compare); if (compare === true) scrollToInfo(); }} type={'secondary'} title="ocultar detalle" className={'text-center m-auto justify-center rounded-lg border-2 py-2 font-semibold text-base flex flex-row uppercase bg-white hover:bg-opacity-85'} size={'full'} icon={<ArrowLeft size={24} color={'rgb(124 19 56 / var(--tw-bg-opacity))'} opacity={1.0} />} />
							</div>
						</div>
					</div>
					:
					<></>
				}
			</div>
		</>
	);
};

QuotationOptions.propTypes = {
	coverages: PropTypes.array,
	setSelectedCoverage: PropTypes.func
};

export default QuotationOptions;