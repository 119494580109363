import './Step3.css';
import React, { useEffect, useState } from 'react';
import { ArrowLeft, ArrowRight, EditPencil } from '../../../assets/icons';
import { useMyContext } from '../../../context/global/Context';
import PersonalDataForm from '../../PersonalDataForm/PersonalDataForm';
import { Button, Check, Input, Label } from '../../basic';
import { useDataForm, useFetchGoogleAnalytics, usePersonalData } from '../../../hooks';
import { onlyNumber, onlyLetter, validateLicence, validatePhoneNumber, validateRequiredFields, validateEmail, withoutSpaces, withoutSpecialChars, isEmpty } from '../../../utils/validation';
import ErrorModal from '../../ErrorModal/ErrorModal';
import { defaultArrayColors } from '../../../config';

export const Step3 = () => {
	const { functions: { handleStepQuotation, handleVehicleData, handleSearchPersonByDni, handleContractQuoteForm }, vehicleData, personData, quoteForm, currentBranch, maxDaysNoBearingCertificate, maxSizeNoBearingCertificate, potencialPolicy } = useMyContext();
	const { dataPerson, modifyDataPerson } = usePersonalData();
	const { dataForm, modifyDataForm } = useDataForm();
	const { beginCheckout } = useFetchGoogleAnalytics(3);

	const [loadingUpdateClient, setLoadingUpdateClient] = useState(false);

	const [modalType, setModalType] = useState('plain');
	const [modalTitle, setModalTitle] = useState('');
	const [modalText, setModalText] = useState('');
	const [modalShow, setModalShow] = useState(false);

	const [licenseToDeclare, setLicenseToDeclare] = useState(false);
	const [prevLicense, setPrevLicense] = useState('');

	const defaultLabelTitle = 'adjuntar';
	const [labelTitle, setLabelTitle] = useState(defaultLabelTitle);

	const [arrayColors, setArrayColors] = useState(defaultArrayColors);

	useEffect(() => {
		beginCheckout();
		modifyDataPerson(personData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personData]);

	useEffect(() => {
		let colors = defaultArrayColors;

		colors.dni.completed = !isEmpty(personData.DocumentoNumero);
		colors.cuit.completed = !isEmpty(personData.Cuit);
		colors.nombre.completed = !isEmpty(personData.Nombre);
		colors.apellido.completed = !isEmpty(personData.Apellido);
		colors.area.completed = !isEmpty(personData.Area);
		colors.telefono.completed = !isEmpty(personData.Telefono);
		colors.email.completed = !isEmpty(personData.EMail);
		colors.postalCode.completed = !isEmpty(personData.Direccion.CodigoPostal);
		colors.calle.completed = !isEmpty(personData.Direccion.CalleNombre);
		colors.numero.completed = !isEmpty(personData.Direccion.CalleNumero);
		colors.piso.completed = !isEmpty(personData.Direccion.CallePiso);
		colors.depto.completed = !isEmpty(personData.Direccion.CalleDepto);

		setArrayColors(colors);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [personData]);

	useEffect(() => {
		modifyDataForm(quoteForm);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quoteForm]);

	useEffect(() => {
		if (potencialPolicy.hideNoBearingCertificate === false) {
			if (!isEmpty(quoteForm.noBearingCertificate)) {
				setLicenseToDeclare(true);
				setLabelTitle(quoteForm.noBearingCertificate.name);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [quoteForm.noBearingCertificate, potencialPolicy.hideNoBearingCertificate]);

	const confirmPersonalData = async () => {
		setLoadingUpdateClient(true);

		const resultValidation = validateRequiredFields('', 'form_step_3');

		const resultLicense = validateLicence(vehicleData.Patente, currentBranch);

		const resultEmail = validateEmail(dataPerson.EMail);

		let resultValidationFile = true;

		if (vehicleData.CeroKm === true && licenseToDeclare === true && potencialPolicy.hideNoBearingCertificate === false) {
			resultValidationFile = !isEmpty(dataForm.noBearingCertificate);
		}

		let validateAreaPhone = true;
		if (dataPerson.Area.length === 2) {
			validateAreaPhone = parseInt(dataPerson.Area) === 11;
		}

		const phoneLength = dataPerson.Area.length + dataPerson.Telefono.length;
		const validatePhoneLength = phoneLength >= 10 && phoneLength <= 12;

		let errors = [];

		if (resultValidation === true && resultLicense === true && resultEmail === true && validatePhoneLength === true && validateAreaPhone === true && resultValidationFile === true) {
			const response = await handleContractQuoteForm(dataForm, dataPerson);

			if (response.error === true) {
				setModalTitle('Atención');
				setModalText(response.msg);
				setModalType('error');
				setModalShow(true);
			}
		} else {
			if (resultLicense === false) {
				let licenseExample;
				switch (currentBranch) {
					case 3:
						licenseExample = 'ABC123 o AB123CD';
						break;
					case 4:
						licenseExample = '123ABC o A123BCD';
						break;
					default:
						licenseExample = '';
						break;
				}

				errors.push(`Debes ingresar una patente válida (${licenseExample}).`);
			}

			if (validateAreaPhone === false) {
				errors.push(`El área del número de teléfono es incorrecto.`);
			}

			if (validatePhoneLength === false) {
				errors.push(`Asegurate de ingresar un número de teléfono válido.`);
			}

			if (resultEmail === false) {
				errors.push(`Asegurate de ingresar un email válido.`);
			}

			if (resultValidationFile === false) {
				errors.push(`Asegurate de cargar un archivo de NO RODAMIENTO válido.`);
			}

			if (resultValidation === false) {
				errors = [];
				errors[0] = `Asegurate de completar todos los datos obligatorios antes de continuar.`;
			}
		}

		if (!isEmpty(errors)) {
			setModalTitle('Atención');
			setModalType('error');
			setModalText(
				<ul>
					{
						errors.map(e => {
							return <li className='text-sm font-normal text-black tracking-normal'>{e}</li>
						})
					}
				</ul>
			);
			setModalShow(true);
		}

		setLoadingUpdateClient(false);
	}

	const handleChangeInput = async (type, value) => {
		let colors = arrayColors;
		let numberZero = false;

		switch (type) {
			case 'chasis':
				if (withoutSpaces(value) && withoutSpecialChars(value)) modifyDataForm({ chasisNumber: value.toUpperCase() });
				break;
			case 'motor':
				if (withoutSpaces(value) && withoutSpecialChars(value)) modifyDataForm({ motorNumber: value.toUpperCase() });
				break;
			case 'patente':
				if (withoutSpaces(value) && withoutSpecialChars(value)) handleVehicleData({ Patente: value.toUpperCase() });
				break;
			case 'dni':
				if (!isEmpty(value)) {
					colors.dni.completed = true;
				} else {
					colors.dni.completed = false;
				}

				setArrayColors(colors);

				if (onlyNumber(value)) {
					modifyDataPerson({ DocumentoNumero: value });
					await handleSearchPersonByDni(value);
				}
				break;
			case 'cuit':
				if (!isEmpty(value)) {
					colors.cuit.completed = true;
				} else {
					colors.cuit.completed = false;
				}

				setArrayColors(colors);

				if (onlyNumber(value)) {
					modifyDataPerson({ Cuit: value });
				}
				break;
			case 'nombre':
				if (!isEmpty(value)) {
					colors.nombre.completed = true;
				} else {
					colors.nombre.completed = false;
				}

				setArrayColors(colors);

				if (onlyLetter(value)) modifyDataPerson({ Nombre: value });
				break;
			case 'apellido':
				if (!isEmpty(value)) {
					colors.apellido.completed = true;
				} else {
					colors.apellido.completed = false;
				}

				setArrayColors(colors);

				if (onlyLetter(value)) modifyDataPerson({ Apellido: value });
				break;
			case 'fechaNacimiento':
				modifyDataPerson({ FechaNacimiento: value });
				break;
			case 'sexo':
				modifyDataPerson({ Sexo: value });
				break;
			case 'nacionalidad':
				modifyDataPerson({ Nacionalidad: parseInt(value) });
				break;
			case 'area':
				if (!isEmpty(value)) {
					colors.area.completed = true;
				} else {
					colors.area.completed = false;
				}

				setArrayColors(colors);

				numberZero = value.length === 1 && isEmpty(value);

				if (validatePhoneNumber(value) && onlyNumber(value) && !numberZero) modifyDataPerson({ Area: value });
				break;
			case 'telefono':
				if (!isEmpty(value)) {
					colors.telefono.completed = true;
				} else {
					colors.telefono.completed = false;
				}

				setArrayColors(colors);

				if (validatePhoneNumber(value) && onlyNumber(value)) modifyDataPerson({ Telefono: value });
				break;
			case 'email':
				if (!isEmpty(value)) {
					colors.email.completed = true;
				} else {
					colors.email.completed = false;
				}

				setArrayColors(colors);

				modifyDataPerson({ EMail: value });
				break;
			case 'postalCode':
				if (!isEmpty(value)) {
					colors.postalCode.completed = true;
				} else {
					colors.postalCode.completed = false;
				}

				setArrayColors(colors);

				if (onlyNumber(value)) modifyDataPerson({ Direccion: { CodigoPostal: value } });
				break;
			case 'calle':
				if (!isEmpty(value)) {
					colors.calle.completed = true;
				} else {
					colors.calle.completed = false;
				}

				setArrayColors(colors);

				if (withoutSpecialChars(value, true)) modifyDataPerson({ Direccion: { CalleNombre: value } });
				break;
			case 'numero':
				if (!isEmpty(value)) {
					colors.numero.completed = true;
				} else {
					colors.numero.completed = false;
				}

				setArrayColors(colors);

				numberZero = value.length === 1 && isEmpty(value);

				if (onlyNumber(value) && !numberZero) modifyDataPerson({ Direccion: { CalleNumero: value } });
				break;
			case 'piso':
				if (!isEmpty(value)) {
					colors.piso.completed = true;
				} else {
					colors.piso.completed = false;
				}

				setArrayColors(colors);

				if (withoutSpecialChars(value, true)) modifyDataPerson({ Direccion: { CallePiso: value } });
				break;
			case 'depto':
				if (!isEmpty(value)) {
					colors.depto.completed = true;
				} else {
					colors.depto.completed = false;
				}

				setArrayColors(colors);

				if (withoutSpecialChars(value, true)) modifyDataPerson({ Direccion: { CalleDepto: value.toUpperCase() } });
				break;
			default:
				break;
		}
	}

	const handleCheckLicense = () => {
		const checked = !licenseToDeclare;

		if (checked === false) {
			if (prevLicense === 'A DECLARAR')
				handleVehicleData({ Patente: '' });
			else
				handleVehicleData({ Patente: prevLicense });

			if (potencialPolicy.hideNoBearingCertificate === false)
				emptyFile();
		} else {
			setPrevLicense(vehicleData.Patente);
			handleVehicleData({ Patente: 'A DECLARAR' });
		}

		setLicenseToDeclare(checked);
	}

	const emptyFile = () => {
		setLabelTitle(defaultLabelTitle);
		modifyDataForm({ noBearingCertificate: null });
		document.getElementById('certificado_no_rodamiento').value = '';
		document.getElementById('certificado_no_rodamiento_mobile').value = '';
	}

	const handleChangeFile = (e) => {
		const file = e.target.files[0];

		const today = new Date();

		let error = false;
		let msgError = '';

		if (!isEmpty(file)) {
			if (file.lastModified >= today.setDate(today.getDate() - maxDaysNoBearingCertificate)) {
				// Esto quiere decir que el archivo tiene como máximo 3 días de antiguedad (viene como parámetros)
				const fileSize = (file.size / 1000) / 1000; // lo convierto a kilobyte, y después a megabyte

				if (fileSize <= (maxSizeNoBearingCertificate)) {
					setLabelTitle(file.name);
					modifyDataForm({ noBearingCertificate: file });
				} else {
					emptyFile();

					error = true;
					msgError = `El archivo supera el tamaño máximo permitido: ${maxSizeNoBearingCertificate}mb.`;
				}
			} else {
				emptyFile();
				error = true;

				if (maxDaysNoBearingCertificate === 1) {
					msgError = `Asegurate de cargar un archivo cuya antigüedad no supere al día anterior.`;
				} else if (maxDaysNoBearingCertificate > 1) {
					msgError = `Asegurate de cargar un archivo cuya antigüedad no supere los ${maxDaysNoBearingCertificate} días.`;
				} else if (maxDaysNoBearingCertificate === 0) {
					msgError = `Asegurate de cargar un archivo que corresponda al día actual.`;
				}
			}
		} else {
			emptyFile();
		}

		if (error === true) {
			setModalTitle('Atención');
			setModalText(msgError);
			setModalType('error');
			setModalShow(true);
		}
	}

	return (
		<div id="form_step_3" className='step3 px-6 md:px-0'>
			<ErrorModal type={modalType} text={modalText} title={modalTitle} visible={modalShow} handleVisible={() => { setModalShow(!modalShow) }} />
			<h3 className="font-bold text-primary text-2xl my-6 text-center md:text-start">Datos de tu {vehicleData?.MarcaDescripcion}</h3>
			<div className="flex flex-col md:flex-row justify-between gap-x-10">
				{
					vehicleData.QuoteType === 'M' ?
						<>
							<div className="flex flex-col w-full mb-6 md:mb-0">
								<Input type='text' label={'PATENTE *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' handleChange={(e) => { handleChangeInput('patente', e.target.value) }} value={vehicleData.Patente} required maxLength={7} inputIcon={vehicleData?.Patente ? <></> : <EditPencil size={20} color={'black'} className='absolute top-[30%] right-4' />} editable={!licenseToDeclare} />
							</div>
							{
								vehicleData.CeroKm === true ?
									<div className='grid md:hidden grid-cols-1 gap-y-6 gap-x-0 mb-6'>
										<div className='flex flex-row items-center justify-start gap-x-2 text-nowrap'>
											<Check checked={licenseToDeclare} handleCheck={handleCheckLicense} />
											<Label title={'patente a declarar'} className={'uppercase text-xs font-semibold text-shiny-pink'} />
										</div>
										<div className="flex flex-col self-center">
											<p className='text-shiny-pink font-semibold text-sm text-left text-pretty'>Certificado de No Rodamiento o Factura de Compra emitidos por el concesionario oficial</p>
										</div>
										<div className="flex flex-col items-center">
											<Input type='file' className={'hidden'} name='certificado_no_rodamiento_mobile' accept='.jpg, .jpeg, .png, .pdf' handleChange={(e) => { handleChangeFile(e) }} />
											<div className={`w-full ${licenseToDeclare ? '' : 'pointer-events-none'}`} onClick={() => {
												document.getElementById('certificado_no_rodamiento_mobile').click();
											}}>
												<Label title={<span className='flex flex-row justify-between text-xs self-center items-center select-none'>{labelTitle}<ArrowRight size={18} color='white' /></span>} className={`text-white px-3 md:px-4 py-2 md:py-1 bg-shiny-pink ${licenseToDeclare ? 'cursor-pointer' : 'bg-opacity-60 pointer-events-none'} rounded w-full text-center uppercase font-semibold`} />
												<span className='text-xs italic text-black'>Formatos admitidos: .jpg, .jpeg, .png, .pdf</span>
											</div>
										</div>
									</div>
									:
									<></>
							}
						</>
						:
						<></>
				}
				<div className="flex flex-col w-full mb-6 md:mb-0">
					<Input type='text' label={'Nº DE CHASIS *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' editable={vehicleData?.Chasis ? false : true} handleChange={(e) => { handleChangeInput('chasis', e.target.value) }} value={dataForm.chasisNumber} required maxLength={25} inputIcon={vehicleData?.Chasis ? <></> : <EditPencil size={20} color={'black'} className='absolute top-[30%] right-4' />} />
				</div>
				<div className="flex flex-col w-full">
					<Input type='text' label={'Nº DE MOTOR *'} placeholder={''} border='border-2 border-b-shiny-pink border-t-white border-x-white' className={''} size='w-full' editable={vehicleData?.Motor ? false : true} handleChange={(e) => { handleChangeInput('motor', e.target.value) }} value={dataForm.motorNumber} required maxLength={25} inputIcon={vehicleData?.Motor ? <></> : <EditPencil size={20} color={'black'} className='absolute top-[30%] right-4' />} />
				</div>
			</div>
			{
				vehicleData.CeroKm === true ?
					<div className='hidden md:grid grid-cols-3 gap-y-0 gap-x-10 mt-6'>
						<div className='flex flex-row items-center justify-center gap-x-2 text-nowrap'>
							<Check checked={licenseToDeclare} handleCheck={handleCheckLicense} />
							<Label title={'patente a declarar'} className={'uppercase text-xs font-semibold text-shiny-pink'} />
						</div>
						{
							potencialPolicy.hideNoBearingCertificate === true ?
								<></>
								:
								<>
									<div className="flex flex-col self-center">
										<p className='text-shiny-pink font-semibold text-sm text-left text-pretty'>Certificado de No Rodamiento o Factura de Compra emitidos por el concesionario oficial</p>
									</div>
									<div className="flex flex-col items-center">
										<Input type='file' className={'hidden'} name='certificado_no_rodamiento' accept='.jpg, .jpeg, .png, .pdf' handleChange={(e) => { handleChangeFile(e) }} />
										<div className={`w-full ${licenseToDeclare ? '' : 'pointer-events-none'}`} onClick={() => {
											document.getElementById('certificado_no_rodamiento').click();
										}}>
											<Label title={<span className='flex flex-row justify-between text-xs self-center items-center select-none'>{labelTitle}<ArrowRight size={18} color='white' /></span>} className={`text-white px-3 md:px-4 py-2 md:py-1 bg-shiny-pink ${licenseToDeclare ? 'cursor-pointer' : 'bg-opacity-60 pointer-events-none'} rounded w-full text-center uppercase font-semibold`} />
											<span className='text-xs italic text-black'>Formatos admitidos: .jpg, .jpeg, .png, .pdf</span>
										</div>
									</div>
								</>
						}
					</div>
					:
					<></>
			}
			<PersonalDataForm personData={dataPerson} handleChangeData={handleChangeInput} completedFields={arrayColors} />
			<div className="flex flex-col-reverse md:flex-row mt-4 pt-1 w-full md:w-3/4 mx-auto justify-center">
				{/* <div className="flex w-full md:w-auto justify-center md:justify-start">
					<Button title="volver al paso anterior" className={'border-0 py-2 font-semibold text-base uppercase bg-white text-light-grey-disabled flex flex-row items-center'} icon={<ArrowLeft size={23} color={'#A1AEB7'} className={'ms-4'} />} size={'auto'} handleClick={() => { handleStepQuotation(2) }} disabled={false} />
				</div> */}
				<div className="w-full md:w-3/6 mb-4 md:mb-0">
					<Button title="continuar" className={'uppercase bg-primary text-white rounded-md py-2 hover:bg-opacity-85'} size={'full'} handleClick={confirmPersonalData} disabled={loadingUpdateClient} loading={loadingUpdateClient} />
				</div>
			</div>
		</div>
	);
};